<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12rem"
      :class="$style.form"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Название в МойСклад">
        <el-input v-model="form.moySkladName" readonly></el-input>
      </el-form-item>
      <el-form-item label="МойСклад ID">
        <el-input v-model="form.moySkladId" readonly></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <el-input v-model="form.description" type="textarea"></el-input>
      </el-form-item>
      <div style="display:flex; flex-direction: column;">
        <el-form-item label="Порядок" prop="orderField">
          <el-input-number
            v-model.number="form.orderField"
            controls-position="right"
            :min="0"
          >
          </el-input-number>
        </el-form-item>
        <div :class="$style.flexWrapper">
          <el-form-item label="Активный">
            <el-checkbox v-model="form.isActive"></el-checkbox>
          </el-form-item>
          <el-form-item label="Модульбанк">
            <el-checkbox v-model="form.isMBPay"></el-checkbox>
          </el-form-item>
          <el-form-item label="ЮКасса">
            <el-checkbox v-model="form.isYMPay"></el-checkbox>
          </el-form-item>
        </div>
        <h4>Доступность</h4>
        <div :class="$style.flexWrapper">
          <el-form-item label="Самовывоз" prop="isAvailableForSelfDelivery">
            <el-checkbox
              v-model="form.isAvailableForSelfDelivery"
            ></el-checkbox>
          </el-form-item>
          <el-form-item label="По умолчанию" prop="isDefault">
            <el-checkbox
              v-model="form.isDefault"
            ></el-checkbox>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import { formatInputDate } from '@/helpers'

export default {
  async created() {
    await this.getPaymentType()
  },
  data() {
    return {
      form: {
        name: '',
        value: '',
        description: '',
        isActive: false,
        isDefault: false,
        isAvailableForSelfDelivery: false
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        value: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    async getPaymentType() {
      const loading = this.$loading({
        lock: true
      })

      const {
        value,
        error
      } = await delivery.PaymentServiceCore.PaymentTypesActions.getById(
        this.$route.params.id
      )
      if (error) return
      this.form = value
      loading.close()
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const result = await delivery.PaymentServiceCore.PaymentTypesActions.update(
            this.$route.params.id,
            this.form
          )
          if (result.error) {
            alert('Ошибка редактирования способа оплаты: ' + result.error)
          } else {
            alert('Способ оплаты успешно изменен')
            this.$router.push('/addwine/payments/payment-types')
          }
          loading.close()
        }
      })
    },
    formatInputDate(date) {
      return date == null ? '' : formatInputDate(date)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.promos {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .form {
    .flexWrapper {
      display: flex;
    }
  }
  label {
    margin: 0;
  }
}
</style>
